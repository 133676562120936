import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { setToken } from '@/App/Utils/axios'
import { UserService } from '@/App/Services/User.service'
import { UserInfo } from '@/App/Services/interfaces/user.interface'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class LoginPage extends Mixins(Validator) {
  @sharedStore.Mutation
  private readonly setUserInfo: (user: UserInfo) => void

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isPasswordShow = false
  public isLoading = false
  public login = ''
  public language = 'en'
  public password = ''
  public logo = require('../../../assets/logo-login-screen.png')

  public async onLogin(): Promise<void> {
    try {
      this.isLoading = true

      const user = await UserService.auth(this.login, this.password)

      this.setUserInfo(user)
      localStorage.setItem('user', JSON.stringify(user))

      setToken(user.token)

      this.$router.push('/users')
    } finally {
      this.isLoading = false
    }
  }

  public localizationChanged(localization:string): void {
    this.$i18n.locale=localization
    localStorage.setItem('localization', localization )
  }

  public mounted() {
    const localization = localStorage.getItem('localization')
    if (localization) {
      this.language=localization
    }
  }

  public loginFieldValidator(val: string): ValidationResult {
    return this.validator.isEmpty(val, this.$t('loginPage.loginValidationText').toString())
  }

  public passwordFieldValidator(val: string): ValidationResult {
    return this.validator.isEmpty(val, this.$t('loginPage.passwordValidationText').toString())
  }

  public changeInputStatus(): void {
    this.isPasswordShow = !this.isPasswordShow
  }

  get inputType(): 'text' | 'password' {
    return this.isPasswordShow ? 'text' : 'password'
  }

  get inputIcon(): string {
    return this.isPasswordShow ? 'fa fa-eye' : 'fa fa-eye-slash'
  }
}
