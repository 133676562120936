var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"height":"250px","contain":"","src":_vm.logo}}),_c('v-text-field',{attrs:{"dark":"","color":"white","label":_vm.$t('loginPage.loginInputLabel'),"rules":[_vm.loginFieldValidator],"outlined":""},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('v-text-field',{attrs:{"dark":"","color":"white","label":_vm.$t('loginPage.passwordInputLabel'),"outlined":"","type":_vm.inputType,"append-icon":_vm.inputIcon,"rules":[_vm.passwordFieldValidator]},on:{"click:append":_vm.changeInputStatus},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('a',{staticClass:"white--text cursor-pointer",on:{"click":function($event){return _vm.$router.push('/login/password-reset')}}},[_vm._v(" "+_vm._s(_vm.$t('loginPage.resetPasswordText'))+" ")]),_c('v-btn',{staticClass:"mt-1",attrs:{"large":"","block":"","dark":"","color":"rgb(227, 54, 60)","loading":_vm.isLoading,"disabled":!_vm.login || !_vm.password},on:{"click":_vm.onLogin}},[_vm._v(" "+_vm._s(_vm.$t('loginPage.loginBtnText'))+" ")]),_c('v-select',{staticClass:"mt-2",attrs:{"label":"Select language","large":"","block":"","dark":"","color":"rgb(227, 54, 60)","items":[ {text:'English',
				value:'en'},{
					text:'Spanish',
					value:'es'
				},{
				text:'Russian',
				value:'ru'
			}]},on:{"change":_vm.localizationChanged},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }